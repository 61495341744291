import { MouseEvent, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { RoutePath, RouteTo } from '../../routes.ts'

import styles from './Nav.module.scss'

import logoImg from '../../assets/logo.svg'

export enum NavigationTab {
    Packs = 'packs',
    Carousels = 'carousels',
    ShopTabs = 'shoptabs',
    Maps = 'maps',
    Gifts = 'gifts',
    Medias = 'medias',
    Accounts = 'accounts',
    Versions = 'versions',
    InAppEvents = 'iaes',
    Tools = 'tools',
    PaApple = 'price-automation-apple',
    PaGoogle = 'price-automation-google',
}

export function getTabByRoute(routeName: keyof typeof RoutePath) {
    switch (routeName) {
        case 'Packs':
        case 'PackEdit':
        case 'PackNew':
            return NavigationTab.Packs

        case 'Carousels':
        case 'CarouselEdit':
        case 'CarouselNew':
            return NavigationTab.Carousels

        case 'Tabs':
        case 'TabEdit':
        case 'TabNew':
            return NavigationTab.ShopTabs

        case 'Gifts':
        case 'GiftEdit':
        case 'GiftNew':
            return NavigationTab.Gifts

        case 'Maps':
        case 'MapEdit':
            return NavigationTab.Maps

        case 'Tools':
            return NavigationTab.Tools

        case 'Versions':
            return NavigationTab.Versions

        case 'InAppEvents':
        case 'InAppEventsEdit':
        case 'InAppEventsNew':
            return NavigationTab.InAppEvents

        case 'Medias':
            return NavigationTab.Medias

        case 'PriceAutomationApple':
            return NavigationTab.PaApple
        case 'PriceAutomationGoogle':
            return NavigationTab.PaGoogle
        default:
            return undefined
    }
}

type NavProps = {
    tab?: NavigationTab
    onTabChange: (e: MouseEvent<HTMLAnchorElement>, tab: NavigationTab) => void
    isInAppEventsEnabled: boolean
    children: ReactNode
}

const Nav = (props: NavProps) => {
    const { onTabChange, tab, isInAppEventsEnabled, children } = props

    const getStatusClass = (linkName: NavigationTab) =>
        tab === linkName ? styles.active : ''

    return (
        <nav key={tab} className={cn(styles.navigation, 'blurrable')}>
            <div className={styles.linksWrapper}>
                <Link
                    onClick={(e) => onTabChange(e, NavigationTab.Packs)}
                    to={RouteTo.Packs()}
                    className={styles.logoWrapperLink}
                >
                    <img
                        alt="tocaboca_logo"
                        className={styles.logo}
                        src={logoImg}
                    />
                </Link>
                <Link
                    onClick={(e) => onTabChange(e, NavigationTab.Packs)}
                    className={cn(getStatusClass(NavigationTab.Packs))}
                    to={RouteTo.Packs()}
                >
                    Packs
                </Link>
                <Link
                    onClick={(e) => onTabChange(e, NavigationTab.Carousels)}
                    className={cn(getStatusClass(NavigationTab.Carousels))}
                    to={RouteTo.Carousels()}
                >
                    Carousels
                </Link>
                <Link
                    onClick={(e) => onTabChange(e, NavigationTab.ShopTabs)}
                    className={cn(getStatusClass(NavigationTab.ShopTabs))}
                    to={RouteTo.Tabs()}
                >
                    Shop Tabs
                </Link>
                <Link
                    onClick={(e) => onTabChange(e, NavigationTab.Maps)}
                    className={cn(getStatusClass(NavigationTab.Maps))}
                    to={RouteTo.Maps()}
                >
                    Maps
                </Link>
                <Link
                    onClick={(e) => onTabChange(e, NavigationTab.Gifts)}
                    className={cn(getStatusClass(NavigationTab.Gifts))}
                    to={RouteTo.Gifts()}
                >
                    Gifts
                </Link>
                <Link
                    onClick={(e) => onTabChange(e, NavigationTab.Medias)}
                    className={cn(getStatusClass(NavigationTab.Medias))}
                    to={RouteTo.Medias()}
                >
                    Media Library
                </Link>
                <Link
                    onClick={(e) => onTabChange(e, NavigationTab.Accounts)}
                    className={cn(getStatusClass(NavigationTab.Accounts))}
                    to={RouteTo.Accounts()}
                >
                    Account management
                </Link>
                <div className={styles.submenuWrapper}>
                    <button type="button" className={styles.submenuButton}>
                        Price Automation <span style={{ fontSize: 11 }}>▼</span>
                    </button>
                    <div className={styles.submenu}>
                        <Link
                            onClick={(e) =>
                                onTabChange(e, NavigationTab.PaApple)
                            }
                            className={cn(
                                getStatusClass(NavigationTab.PaApple)
                            )}
                            to={RouteTo.PriceAutomationApple()}
                        >
                            Apple Connect
                        </Link>
                        <Link
                            onClick={(e) =>
                                onTabChange(e, NavigationTab.PaGoogle)
                            }
                            className={cn(
                                getStatusClass(NavigationTab.PaGoogle)
                            )}
                            to={RouteTo.PriceAutomationGoogle()}
                        >
                            Google Play
                        </Link>
                    </div>
                </div>
                <div className={styles.submenuWrapper}>
                    <button type="button" className={styles.submenuButton}>
                        More <span style={{ fontSize: 11 }}>▼</span>
                    </button>
                    <div className={styles.submenu}>
                        <Link
                            onClick={(e) =>
                                onTabChange(e, NavigationTab.Versions)
                            }
                            className={cn(
                                getStatusClass(NavigationTab.Versions)
                            )}
                            to={RouteTo.Versions()}
                        >
                            Release management
                        </Link>
                        {isInAppEventsEnabled && (
                            <Link
                                onClick={(e) =>
                                    onTabChange(e, NavigationTab.InAppEvents)
                                }
                                className={cn(
                                    getStatusClass(NavigationTab.InAppEvents)
                                )}
                                to={RouteTo.InAppEvents()}
                            >
                                In-App Events
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.childrenWrapper}>{children}</div>
        </nav>
    )
}

export default Nav
