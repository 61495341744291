import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import axios from 'axios'
import _ from 'lodash'

import { SaveStatus } from '../../shared/SavePanel'
import { ShopTabAssetsWrapper } from './AssetsSection/ShopTabAssetsWrapper'
import { ShopTabPacks } from './PacksSection/ShopTabPacks'
import {
    LabelInput,
    Modal,
    NewRow,
    SavePanel,
    Section,
    SpeechBubble,
} from '../../shared'

import { handleError, languages } from '../../utils/utils'

import { RouteTo } from '../../routes.ts'
import { shopTabTypeLabels } from '../../settings'

import { LanguageType } from '../../types/enums/languagetype.enum'
import { MediaType } from '../../types/enums/mediatype.enum'
import { TabType } from '../../types/enums/tabtype.enum'

import { MediaOrImageFileUploading, Tab, TabCreate } from '../../types/tabTypes'

import validate from './validate'

type ShopTabProps = {
    language: LanguageType
    openSections: string[]
    toggleSection: (name: string) => void
}

const ShopTab = function (props: ShopTabProps) {
    const emptyShopTab: Tab = {
        id: -1,
        name: '',
        active: false,
        displayName: {},
        mediaIds: [],
        medias: [],
        type: TabType.PACKS_LIST,
        priority: 0,
        packIds: [],
    }
    const language = props.language
    const navigate = useNavigate()
    const params = useParams()
    const id = params.id
    const [hasLoaded, setHasLoaded] = useState(false)
    const isNew = () => !id
    const [shopTab, setShopTab] = useState<Tab>(emptyShopTab)
    const [loadGoogleSheetStateText, setLoadGoogleSheetStateText] = useState('')
    const [saveStatus, setSaveStatus] = useState(SaveStatus.NO_CHANGES)

    const getTabDataForRequest = (): Tab | TabCreate => {
        return isNew() ? _.omit(shopTab, 'id') : shopTab
    }

    const validationResults = validate(getTabDataForRequest(), isNew())

    const fetchData = async () => {
        setHasLoaded(true)
        try {
            if (!isNew()) {
                const response = await axios.get<Tab>(`/api/v2/shoptabs/${id}`)
                if (response.data?.medias === null) {
                    response.data.medias = []
                }
                setShopTab(response.data)
            }
        } catch (e) {
            handleError('Error receiving shop tab', e)
        }
    }

    useEffect(() => {
        if (!hasLoaded) fetchData()
        // ToDo: add has changed check
    }, [])

    useEffect(() => {
        setSaveStatus(SaveStatus.HAS_CHANGED)
    }, [shopTab])

    const loadGoogleSheetData = async () => {
        try {
            setLoadGoogleSheetStateText('Loading from google...')
            const stateData = _.cloneDeep(shopTab)
            let message = ''
            let hasError = false

            const ret = await axios.get(
                '/api/v2/getpackfromsource/' + stateData.name
            )

            // Everything went well, but there are no translations from Google sheet for this string ID
            if (_.isEmpty(ret.data.name)) {
                message = 'There are no translations for this string ID!'
                hasError = true
            }

            if (hasError === false) {
                message = `I've updated the text for you!`

                setShopTab({ ...stateData, displayName: ret.data.name })
            }
            setLoadGoogleSheetStateText(message)
        } catch (error) {
            let errorMessage = 'Oops! Something went wrong!'
            if (axios.isAxiosError(error)) {
                errorMessage = `That didn't work. Sorry! Server says: ${error.response?.data.message}`
            }
            setLoadGoogleSheetStateText(errorMessage)
        }
        setTimeout(() => {
            setLoadGoogleSheetStateText('')
        }, 5000)
    }

    const onInputColorChange: ChangeEventHandler<HTMLInputElement> = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        const color = e.target.value === '' ? null : e.target.value
        setShopTab({ ...shopTab, buttonColor: color })
    }

    const onInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        child?: string | null,
        i: number | null = null,
        language?: LanguageType
    ) => {
        const name: string = e.target.name
        const data = _.cloneDeep(shopTab)

        // handle nested objects
        let path = data as Record<string, unknown>

        if (child) {
            const nestedPath = data[child as keyof Tab]

            path = (
                _.isNumber(i) && Array.isArray(nestedPath)
                    ? nestedPath[i]
                    : nestedPath
            ) as Record<string, unknown>
        }

        // handle languages
        if (language) {
            ;(path[name] as Record<LanguageType, string>)[language] =
                e.target.value
        } else {
            path[name] = e.target.value
        }

        // For world map tab we need to clear packIds and backgroundImage
        if (e.target.name === 'type' && e.target.value === TabType.WORLD_MAP) {
            data.packIds = []
        }

        setShopTab(data)
    }

    const onMediaChanged = (items: MediaOrImageFileUploading[]) => {
        const mediaIds = items.reduce((acc: number[], item) => {
            if (item.id) acc.push(item.id)
            return acc
        }, [])
        setShopTab({ ...shopTab, medias: items, mediaIds })
    }

    const onPacksChanged = (packsIds: Tab['packIds']) => {
        setShopTab({ ...shopTab, packIds: packsIds })
    }

    const deleteTab = async (e: { stopPropagation: () => void }) => {
        e.stopPropagation()

        const isConfirmed = await Modal.confirm({
            heading: 'Delete tab',
            text: 'Really delete this tab?',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        try {
            const id = _.cloneDeep(shopTab.id)
            await axios.delete('/api/v2/shoptabs/' + id)
            console.log('Tab deleted')

            navigate(RouteTo.Tabs())
        } catch {
            handleError('Error deleting tab', e)
        }
    }

    const save = async () => {
        setSaveStatus(SaveStatus.SAVING)
        const stateData = _.cloneDeep(getTabDataForRequest())

        // For world map tab we need to clear packIds and backgroundImage
        if (stateData.type === TabType.WORLD_MAP) {
            stateData.packIds = []
            stateData.medias = stateData.medias.filter((media) => {
                return media.type !== MediaType.TAB_BACKGROUND
            })
        }

        try {
            if (isNew()) {
                const res = await axios.post('/api/v2/shoptabs', stateData)
                const id = res.data.id
                if (!id)
                    handleError('No id returned from server', {
                        message: 'No id returned from server',
                    })
                setShopTab({ ...stateData, id })
                navigate(RouteTo.TabEdit(String(id)))
            } else {
                await axios.put(`/api/v2/shoptabs/${id}`, stateData)
            }
            setSaveStatus(SaveStatus.SAVED)
            setTimeout(() => {
                setSaveStatus(SaveStatus.NO_CHANGES)
            }, 2000)
        } catch (e) {
            handleError('Error saving tab', e)
            setSaveStatus(SaveStatus.HAS_CHANGED)
        }
    }

    return (
        <div>
            <Section
                id="tabOverview"
                title={
                    isNew()
                        ? 'New tab: ' + shopTab.name
                        : 'Edit tab: ' + shopTab.name
                }
                {...props}
            >
                <div className="part" style={{ marginBottom: 30 }}>
                    <h2>What kind of tab is this?</h2>
                    <label
                        data-tooltip-content="This type contains packs with any type"
                        data-tooltip-id={TabType.PACKS_LIST}
                    >
                        <input
                            type="radio"
                            value={TabType.PACKS_LIST}
                            name="type"
                            checked={shopTab.type === TabType.PACKS_LIST}
                            onChange={onInputChange}
                        />
                        {shopTabTypeLabels[TabType.PACKS_LIST]}
                    </label>
                    <Tooltip
                        delayShow={500}
                        place="bottom"
                        id={TabType.PACKS_LIST}
                    />
                    <label
                        data-tooltip-content="This tab type is used for showing current active Map"
                        data-tooltip-id={TabType.WORLD_MAP}
                    >
                        <input
                            type="radio"
                            value={TabType.WORLD_MAP}
                            name="type"
                            checked={shopTab.type === TabType.WORLD_MAP}
                            onChange={onInputChange}
                        />
                        {shopTabTypeLabels[TabType.WORLD_MAP]}
                    </label>
                    <Tooltip
                        delayShow={500}
                        place="bottom"
                        id={TabType.WORLD_MAP}
                    />
                </div>
                <div>
                    <h2>Tab Name</h2>
                    <LabelInput
                        object={shopTab}
                        size="wide"
                        name="name"
                        label="Tab String ID"
                        onChange={onInputChange}
                    />
                    <LabelInput
                        object={shopTab}
                        size="wide"
                        name="displayName"
                        tooltip="Localized name (max 30 characters)"
                        language={language}
                        label={'display name in '}
                        labelHighlight={languages[language]}
                        onChange={(e) => onInputChange(e, null, null, language)}
                    />
                    <NewRow />
                    {loadGoogleSheetStateText !== '' ? (
                        <SpeechBubble
                            style={{
                                justifyContent: 'left',
                                marginBottom: 20,
                            }}
                        >
                            {loadGoogleSheetStateText}
                        </SpeechBubble>
                    ) : (
                        ''
                    )}
                    <button onClick={loadGoogleSheetData}>
                        Load from Google Sheet
                    </button>
                </div>
                <NewRow />
            </Section>
            <Section id="tabMedias" title="Tab medias and color" {...props}>
                <ShopTabAssetsWrapper
                    tab={shopTab}
                    onMediaChanged={onMediaChanged}
                    onColorInputChange={onInputColorChange}
                />
            </Section>

            {shopTab.type === TabType.PACKS_LIST && (
                <ShopTabPacks
                    openSections={props.openSections}
                    toggleSection={props.toggleSection}
                    tabPackIds={shopTab.packIds}
                    onPacksChanged={onPacksChanged}
                />
            )}

            <SavePanel
                title={'Working with tab: ' + shopTab.name}
                validationResults={validationResults}
                item={shopTab}
                save={save}
                showDelete={!isNew()}
                saveStatus={saveStatus}
                onDelete={deleteTab}
            />
        </div>
    )
}

export default ShopTab
