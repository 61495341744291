import { pathToRegexp } from 'path-to-regexp'

export enum RoutePath {
    Root = '/',
    Packs = '/packs/',
    PackEdit = '/pack/:id',
    PackNew = '/newpack',
    Login = '/login',
    Gifts = '/gifts/',
    GiftEdit = '/gift/:id',
    GiftNew = '/newgift',
    Maps = '/maps/',
    MapEdit = '/map/:id',
    Carousels = '/carousels/',
    CarouselEdit = '/carousel/:id',
    CarouselNew = '/newcarousel',
    Tabs = '/shoptabs/',
    TabEdit = '/shoptab/:id',
    TabNew = '/newshoptab',
    Accounts = '/accounts/',
    Versions = '/versions/',
    Medias = '/medias',
    PriceAutomationApple = '/price-automation-apple/',
    PriceAutomationGoogle = '/price-automation-google/',
    Tools = '/tools',
    InAppEvents = '/iaes/',
    InAppEventsEdit = '/iae/:id',
    InAppEventsNew = '/newiae',
}

export const RouteTo = {
    Root: () => RoutePath.Root,
    Packs: () => RoutePath.Packs,
    PackEdit: (id: string) => RoutePath.PackEdit.replace(':id', id),
    PackNew: () => RoutePath.PackNew,
    Login: (redirectTo?: string) => {
        return redirectTo
            ? `${RoutePath.Login}?redirectTo=${redirectTo}`
            : RoutePath.Login
    },
    Gifts: () => RoutePath.Gifts,
    GiftEdit: (id: string) => RoutePath.GiftEdit.replace(':id', id),
    GiftNew: () => RoutePath.GiftNew,
    Maps: () => RoutePath.Maps,
    MapEdit: (id: string) => RoutePath.MapEdit.replace(':id', id),
    Carousels: () => RoutePath.Carousels,
    CarouselEdit: (id: string) => RoutePath.CarouselEdit.replace(':id', id),
    CarouselNew: () => RoutePath.CarouselNew,
    Tabs: () => RoutePath.Tabs,
    TabEdit: (id: string) => RoutePath.TabEdit.replace(':id', id),
    TabNew: () => RoutePath.TabNew,
    Accounts: () => RoutePath.Accounts,
    Versions: () => RoutePath.Versions,
    Medias: () => RoutePath.Medias,
    PriceAutomationApple: () => RoutePath.PriceAutomationApple,
    PriceAutomationGoogle: () => RoutePath.PriceAutomationGoogle,
    Tools: () => RoutePath.Tools,
    InAppEvents: () => RoutePath.InAppEvents,
    InAppEventsEdit: (id: string) =>
        RoutePath.InAppEventsEdit.replace(':id', id),
    InAppEventsNew: () => RoutePath.InAppEventsNew,
}

export function getRouteByUrl(url: string): keyof typeof RoutePath | undefined {
    for (const [name, path] of Object.entries(RoutePath)) {
        if (pathToRegexp(path).regexp.test(url)) {
            return name as keyof typeof RoutePath
        }
    }

    return undefined
}
