import { Component, MouseEvent } from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import axios from 'axios'

import Accounts from '../accounts/Accounts'
import Carousel from '../carousels/Carousel'
import Carousels from '../carousels/Carousels'
import Gift from '../gifts/Gift'
import Gifts from '../gifts/Gifts'
import { ApplePriceAutomationPage } from '../iapPriceAutomation/ApplePriceAutomationPage'
import { GooglePriceAutomationPage } from '../iapPriceAutomation/GooglePriceAutomationPage'
import InAppEvent from '../inappevents/InAppEvent'
import InAppEvents from '../inappevents/InAppEvents'
import Map from '../maps/Map'
import Maps from '../maps/Maps'
import Medias from '../media/Medias.tsx'
import Pack from '../packs/Pack'
import Packs from '../packs/Packs'
import LanguageSelector from '../shared/LanguageSelector'
import Loading from '../shared/Loading'
import ShopTab from '../shopTabs/ShopTab/ShopTab'
import ShopTabs from '../shopTabs/ShopTabs'
import Tools from '../tools/Tools'
import { User } from '../user/User'
import Versions from '../versions/Versions'
import { FallbackPage } from './FallbackPage.tsx'
import Login from './Login/Login'
import Nav, { getTabByRoute, NavigationTab } from './Nav/Nav'

import { Features, isFeatureEnabled } from '../utils/features.ts'
import { getUserProfileData } from '../utils/getUserProfileData.ts'
import { redirectToLogin } from '../utils/redirectToLogin.ts'

import { getRouteByUrl, RoutePath } from '../routes.ts'

import { LanguageType } from '../types/enums/languagetype.enum.ts'

import type { UserProfilePublic } from '../user/userTypes'

type AppState = {
    tab?: NavigationTab
    isLoading: boolean
    isAuthenticated: boolean
    isInAppEventsEnabled: boolean
    openSections: string[]
    language: LanguageType
    user: UserProfilePublic | null
}

// Todo Convert to functional component
class App extends Component {
    state: AppState = {
        isLoading: true,
        isAuthenticated: false,
        openSections: ['packOverview'], // Contains list of Section.key to control open sections
        language:
            (localStorage.getItem('language') as LanguageType) ||
            LanguageType.English,
        isInAppEventsEnabled: false,
        user: null,
    }

    componentDidMount = async () => {
        const isInAppEventsEnabled = await isFeatureEnabled(
            Features.in_app_events
        )
        const user = await getUserProfileData()

        this.setState({
            isLoading: false,
            isAuthenticated: Boolean(user),
            user,
            isInAppEventsEnabled,
        })

        localStorage.setItem('hasUnsavedChanges', String(false))
    }

    signout = async (e: MouseEvent<HTMLElement>) => {
        if (!this.onCheckUnsaved(e)) return

        await axios.get('/auth/logout')

        redirectToLogin()
    }

    toggleSection = (name: string) => {
        // todo It should be context
        console.log('Toggle section', name)
        const openSections = this.state.openSections
        const i = openSections.indexOf(name)
        if (i > -1) openSections.splice(i, 1)
        else openSections.push(name)
        this.setState({ openSections })
    }

    onCheckUnsaved = (e: MouseEvent<HTMLElement>) => {
        const hasUnsaved = localStorage.getItem('hasUnsavedChanges') === 'true'
        if (!hasUnsaved) return true
        if (!window.confirm('Really leave? You have unsaved changes.')) {
            e.preventDefault()
            return false
        }
        return true
    }

    onTabChange = (e: MouseEvent<HTMLAnchorElement>, tab: NavigationTab) => {
        if (this.onCheckUnsaved(e)) {
            this.setState({ tab, hasUnsavedChanges: false })
            localStorage.setItem('hasUnsavedChanges', String(false))
        }
    }

    getActiveTab = () => {
        if (this.state.tab) {
            return this.state.tab
        }

        const activeRouteName = getRouteByUrl(window.location.pathname)

        if (activeRouteName) {
            return getTabByRoute(activeRouteName)
        }

        return undefined
    }

    onLanguageChange = (e: MouseEvent<HTMLSelectElement>) => {
        const language = (e.target as HTMLSelectElement).value as LanguageType

        this.setState({ language })
    }

    render() {
        const { isLoading, isAuthenticated, language, isInAppEventsEnabled } =
            this.state
        if (isLoading) return <Loading />

        if (!isAuthenticated) return <Login />

        const tab = this.getActiveTab()

        const allowedOrigins = [
            'http://localhost:*',
            'https://lifeserver-admin.tocaboca.com',
            'https://lifeserver-admin-staging.tocaboca.com',
            'https://lifeserver-admin-dev-growth.tocaboca.com',
            'https://lifeserver-admin-dev-id.tocaboca.com',
            'https://lifeserver-admin-dev-os.tocaboca.com',
            'https://s3.amazonaws.com',
            'https://s3-eu-west-1.amazonaws.com',
        ]

        const contentSecurityPolicy = `connect-src ${allowedOrigins.join(' ')}`
        return (
            <>
                <Helmet>
                    <meta
                        httpEquiv="Content-Security-Policy"
                        content={contentSecurityPolicy}
                    />
                </Helmet>
                <Router>
                    <div>
                        <Nav
                            tab={tab}
                            isInAppEventsEnabled={isInAppEventsEnabled}
                            onTabChange={this.onTabChange}
                        >
                            <LanguageSelector
                                onChange={this.onLanguageChange}
                                language={language}
                                value={language}
                            />

                            {this.state.user && (
                                <User
                                    user={this.state.user}
                                    signout={this.signout}
                                />
                            )}
                        </Nav>
                        <div className="blurrable routesWrapper">
                            <Routes>
                                <Route
                                    path={RoutePath.Packs}
                                    element={<Packs language={language} />}
                                />
                                <Route
                                    path={RoutePath.PackEdit}
                                    element={
                                        <Pack
                                            language={language}
                                            openSections={
                                                this.state.openSections
                                            }
                                            toggleSection={this.toggleSection}
                                        />
                                    }
                                />
                                <Route
                                    path={RoutePath.Gifts}
                                    element={<Gifts />}
                                />
                                <Route
                                    path={RoutePath.GiftEdit}
                                    element={<Gift />}
                                />
                                <Route
                                    path={RoutePath.Maps}
                                    element={<Maps />}
                                />
                                <Route
                                    path={RoutePath.MapEdit}
                                    element={<Map language={language} />}
                                />
                                <Route
                                    path={RoutePath.GiftNew}
                                    element={<Gift />}
                                />
                                <Route
                                    path={RoutePath.PackNew}
                                    element={
                                        <Pack
                                            language={language}
                                            openSections={
                                                this.state.openSections
                                            }
                                            toggleSection={this.toggleSection}
                                        />
                                    }
                                />
                                <Route
                                    path={RoutePath.Carousels}
                                    element={<Carousels />}
                                />
                                <Route
                                    path={RoutePath.Tabs}
                                    element={<ShopTabs language={language} />}
                                />
                                <Route
                                    path={RoutePath.TabNew}
                                    element={
                                        <ShopTab
                                            language={language}
                                            openSections={
                                                this.state.openSections
                                            }
                                            toggleSection={this.toggleSection}
                                        />
                                    }
                                />
                                <Route
                                    path={RoutePath.TabEdit}
                                    element={
                                        <ShopTab
                                            language={language}
                                            openSections={
                                                this.state.openSections
                                            }
                                            toggleSection={this.toggleSection}
                                        />
                                    }
                                />
                                <Route
                                    path={RoutePath.Accounts}
                                    element={<Accounts />}
                                />
                                <Route
                                    path={RoutePath.Versions}
                                    element={<Versions />}
                                />
                                <Route
                                    path={RoutePath.CarouselEdit}
                                    element={
                                        <Carousel
                                            language={language}
                                            openSections={
                                                this.state.openSections
                                            }
                                            toggleSection={this.toggleSection}
                                        />
                                    }
                                />
                                <Route
                                    path={RoutePath.CarouselNew}
                                    element={
                                        <Carousel
                                            language={language}
                                            openSections={
                                                this.state.openSections
                                            }
                                            toggleSection={this.toggleSection}
                                        />
                                    }
                                />
                                <Route
                                    path={RoutePath.Medias}
                                    element={<Medias />}
                                />
                                <Route
                                    path={RoutePath.PriceAutomationApple}
                                    element={<ApplePriceAutomationPage />}
                                />
                                <Route
                                    path={RoutePath.PriceAutomationGoogle}
                                    element={<GooglePriceAutomationPage />}
                                />
                                <Route
                                    path={RoutePath.Tools}
                                    element={<Tools />}
                                />
                                {isInAppEventsEnabled && (
                                    <>
                                        <Route
                                            path={RoutePath.InAppEvents}
                                            element={
                                                <InAppEvents
                                                    language={
                                                        this.state.language
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path={RoutePath.InAppEventsEdit}
                                            element={
                                                <InAppEvent
                                                    language={
                                                        this.state.language
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path={RoutePath.InAppEventsNew}
                                            element={
                                                <InAppEvent
                                                    language={
                                                        this.state.language
                                                    }
                                                />
                                            }
                                        />
                                    </>
                                )}
                                <Route path="*" element={<FallbackPage />} />
                            </Routes>
                        </div>
                    </div>
                </Router>
            </>
        )
    }
}

export default App
