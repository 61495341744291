import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import _ from 'lodash'

import Loading from '../shared/Loading'
import {
    Button,
    ButtonKind,
    Footer,
    Status,
    type StatusProps,
    StatusType,
} from '../shared/ModalV2'
import BulkTable from './BulkTable.tsx'

import type { Gift, GiftChange, GiftChangePair } from './giftTypes.d.ts'
import validate from './validate.ts'

import errorImg from '../assets/error.png'
import infoImg from '../assets/info.svg'
import validImg from '../assets/valid.png'
import warningImg from '../assets/warning.png'

function mapGiftToGiftChange(gift: Gift): GiftChange {
    return _.pick(gift, [
        'giftId',
        'startDate',
        'endDate',
        'releaseNumber',
        'requiredVersion',
    ])
}

function mapGiftsToGiftChangePairs(spreadsheetGifts: Gift[], gifts: Gift[]) {
    const giftChangePairs: { [giftId: string]: GiftChangePair } = {}
    for (const gift of gifts) {
        _.set(
            giftChangePairs,
            `${gift.giftId}.oldGift`,
            mapGiftToGiftChange(gift)
        )
    }
    for (const spreadsheetGift of spreadsheetGifts) {
        _.set(
            giftChangePairs,
            `${spreadsheetGift.giftId}.newGift`,
            mapGiftToGiftChange(spreadsheetGift)
        )
    }

    return Object.values(giftChangePairs).filter(
        ({ oldGift, newGift }) => !_.isEqual(oldGift, newGift)
    )
}

const validateGifts = (gifts: Gift[]) => {
    const validationResults: {
        [giftId: string]: string[]
    } = {}
    for (const gift of gifts) {
        const result = validate(gift)
        const invalidProperties = result.errors.error?.details.map((detail) =>
            detail.path.join('.')
        )
        if (invalidProperties) {
            validationResults[gift.giftId] = invalidProperties
        }
    }
    return validationResults
}

const createGift = async (gift: Gift) => {
    return await axios.post('/api/gift', { gift })
}

const updateGift = async (gift: Gift) => {
    return await axios.put(`/api/gift/${gift.giftId}`, { gift })
}

const deleteGift = async (giftId: string) => {
    return await axios.delete(`/api/gift/${giftId}`)
}

const getStatusContents = (props: {
    fetchError: string
    isTimedout: boolean
}): StatusProps => {
    const { fetchError, isTimedout } = props
    if (fetchError) {
        return {
            type: StatusType.Error,
            header: "Sorry, couldn't reach the server",
            body: 'Please try again or contact system administrator.',
        }
    }
    if (isTimedout) {
        return {
            type: StatusType.Error,
            header: 'Your session has timed out',
            body: 'Please reload the page to fetch the latest contents.',
        }
    }
    return {
        type: StatusType.Info,
        header: 'No need to sync, all gifts are up to date',
        body: 'To add or update a gift please edit the Google Sheet and Sync again.',
    }
}

const getFooterContents = (props: {
    isBulkSuccess: boolean
    bulkError: string
    totalChangeCount: number
    invalidChangesCount: number
}) => {
    const { isBulkSuccess, bulkError, totalChangeCount, invalidChangesCount } =
        props
    const validChangesCount = totalChangeCount - invalidChangesCount

    if (isBulkSuccess) {
        return {
            footerIcon: { alt: 'Success', src: validImg },
            footerTitle: `${validChangesCount} change(s) successfully saved to the CMS`,
            footerDetails:
                'All changes to gifts can now be copied to production. You can now close this window.',
        }
    }
    if (bulkError) {
        return {
            footerIcon: { alt: 'Error', src: errorImg },
            footerTitle: 'Oops, something went wrong',
            footerDetails:
                'Please try again, or contact system administrators for more help.',
        }
    }
    if (totalChangeCount === invalidChangesCount) {
        return {
            footerIcon: { alt: 'No valid gifts', src: errorImg },
            footerTitle: 'No valid gifts to import',
            footerDetails:
                'Any invalid gifts will be skipped, please update in Google Sheet and sync again.',
        }
    }
    if (invalidChangesCount) {
        return {
            footerIcon: { alt: 'Invalid entries', src: warningImg },
            footerTitle: `${validChangesCount} gift change(s) (${invalidChangesCount} Skipped)`,
            footerDetails:
                'Any invalid gifts will be skipped, please update in Google Sheet and sync again.',
        }
    }
    return {
        footerIcon: { alt: 'Ready to save', src: infoImg },
        footerTitle: `Ready to save ${totalChangeCount} change(s) to the CMS`,
        footerDetails:
            'Save changes to the CMS, then copy the gifts to production.',
    }
}

type BulkProps = {
    onClose: () => void
}

function Bulk(props: BulkProps) {
    const { onClose } = props
    const [gifts, setGifts] = useState<Gift[]>([])
    const [giftsError, setGiftsError] = useState<string>('')
    const [isLoadingGifts, setIsLoadingGifts] = useState<boolean>(false)
    const [spreadsheetGifts, setSpreadsheetGifts] = useState<Gift[]>([])
    const [spreadsheetGiftsError, setSpreadsheetGiftsError] =
        useState<string>('')
    const [isLoadingSpreadsheetGifts, setIsLoadingSpreadsheetGifts] =
        useState<boolean>(false)
    const [bulkError, setBulkError] = useState<string>('')
    const [isLoadingBulk, setIsLoadingBulk] = useState<boolean>(false)
    const [isBulkSuccess, setIsBulkSuccess] = useState<boolean>(false)
    const timer = useRef<number | undefined>()
    const [isTimedout, setIsTimedout] = useState<boolean>(false)

    const fetchGifts = async () => {
        setIsLoadingGifts(true)
        setGiftsError('')
        try {
            const { data } = await axios.get('/api/gifts')
            setGifts(data)
        } catch (err: unknown) {
            if (err instanceof Error) {
                setGiftsError(err.message)
            } else {
                setGiftsError('Unknown error')
            }
        }
        setIsLoadingGifts(false)
    }

    const fetchSpreadsheetGifts = async () => {
        setIsLoadingSpreadsheetGifts(true)
        setSpreadsheetGiftsError('')
        try {
            const { data } = await axios.get('/api/v2/googlesheetsgifts')
            setSpreadsheetGifts(data)
        } catch (err: unknown) {
            if (err instanceof Error) {
                setSpreadsheetGiftsError(err.message)
            } else {
                setSpreadsheetGiftsError('Unknown error')
            }
        }
        setIsLoadingSpreadsheetGifts(false)
    }

    const fetchAllGifts = async () => {
        return await Promise.all([fetchGifts(), fetchSpreadsheetGifts()])
    }

    useEffect(() => {
        fetchAllGifts()
    }, [])

    const initializeTimer = () => {
        window.clearTimeout(timer.current)
        setIsTimedout(false)
        timer.current = window.setTimeout(
            () => setIsTimedout(true),
            1000 * 60 * 60
        )
    }

    useEffect(() => {
        initializeTimer()
        return () => window.clearTimeout(timer.current)
    }, [])

    const saveGiftChanges = async (
        giftChangePairs: GiftChangePair[],
        validationResults: { [giftId: string]: string[] }
    ) => {
        setIsLoadingBulk(true)
        setIsBulkSuccess(false)

        const validGiftChangesPairs = giftChangePairs.filter(
            (giftChange) => !validationResults[giftChange.newGift?.giftId]
        )
        const creates = validGiftChangesPairs.filter(
            (giftChange) => giftChange.newGift && !giftChange.oldGift
        )
        const updates = validGiftChangesPairs.filter(
            (giftChange) => giftChange.oldGift && giftChange.newGift
        )
        const deletes = validGiftChangesPairs.filter(
            (giftChange) => giftChange.oldGift && !giftChange.newGift
        )

        try {
            await Promise.all([
                ...creates.map(({ newGift }) => createGift(newGift)),
                ...updates.map(({ newGift }) => updateGift(newGift)),
                ...deletes.map(({ oldGift }) => deleteGift(oldGift.giftId)),
            ])
            setIsBulkSuccess(true)
        } catch (err: unknown) {
            if (err instanceof Error) {
                setBulkError(err.message)
            } else {
                setBulkError('Unknown error')
            }
        }
        setIsLoadingBulk(false)
    }

    if (isLoadingGifts || isLoadingSpreadsheetGifts || isLoadingBulk) {
        return (
            <div>
                <Loading style={{ textAlign: 'center' }} />
            </div>
        )
    }

    const giftChangePairs = mapGiftsToGiftChangePairs(spreadsheetGifts, gifts)

    const validationResults = validateGifts(
        giftChangePairs
            .filter(({ newGift }) => newGift)
            .map(({ newGift }) => newGift)
    )
    const invalidChangesCount = Object.keys(validationResults).length
    const fetchError = giftsError || spreadsheetGiftsError
    const totalChangeCount = giftChangePairs.length

    const { footerIcon, footerTitle, footerDetails } = getFooterContents({
        isBulkSuccess,
        bulkError,
        totalChangeCount,
        invalidChangesCount,
    })

    const hasChangesToSave = Boolean(
        fetchError || !totalChangeCount || isBulkSuccess || isTimedout
    )
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <h2>Review & Import Gifts to CMS</h2>
                    {!fetchError &&
                        Boolean(totalChangeCount) &&
                        !isTimedout && (
                            <div style={{ marginBottom: '16px' }}>
                                {spreadsheetGifts.length} gifts found in Google
                                Sheets ({totalChangeCount} change(s) to review)
                            </div>
                        )}
                </div>
                {!bulkError && !isBulkSuccess && (
                    <div>
                        <button
                            type="button"
                            className="secondary"
                            onClick={() => {
                                fetchAllGifts()
                                initializeTimer()
                            }}
                        >
                            Sync with Google Sheets
                        </button>
                    </div>
                )}
            </div>
            <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                {fetchError || !totalChangeCount || isTimedout ? (
                    <Status
                        {...getStatusContents({
                            fetchError,
                            isTimedout,
                        })}
                    />
                ) : (
                    <BulkTable
                        giftChangePairs={giftChangePairs}
                        validationResults={validationResults}
                    />
                )}
            </div>
            <hr />
            <Footer>
                <div style={{ display: 'flex', columnGap: '8px' }}>
                    {!fetchError &&
                        Boolean(totalChangeCount) &&
                        !isTimedout && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img
                                        style={{ width: '36px' }}
                                        {...footerIcon}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        rowGap: '4px',
                                    }}
                                >
                                    <h3>{footerTitle}</h3>
                                    <div style={{ fontFamily: 'standard' }}>
                                        {footerDetails}
                                    </div>
                                </div>
                            </>
                        )}
                </div>
                {hasChangesToSave ? (
                    <Button kind={ButtonKind.PRIMARY} onClick={onClose}>
                        Close window
                    </Button>
                ) : (
                    <div>
                        <Button kind={ButtonKind.SECONDARY} onClick={onClose}>
                            Discard
                        </Button>
                        <Button
                            kind={ButtonKind.PRIMARY}
                            disabled={totalChangeCount === invalidChangesCount}
                            onClick={() =>
                                saveGiftChanges(
                                    giftChangePairs,
                                    validationResults
                                )
                            }
                        >
                            Save changes
                        </Button>
                    </div>
                )}
            </Footer>
        </>
    )
}

export default Bulk
